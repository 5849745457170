.card-heading {
  font-size: 1.4rem;
  color: #48465b;
  font-weight: 500;
}
@media screen and (max-width: 425px) {
  .card-heading {
    font-size: 16px;
    color: #48465b;
    font-weight: 500;
  }
}
.font-weight-500 {
  font-weight: 500;
}
.badges-text {
  color: #48465b;
  font-size: 0.9rem;
}
.card-heading2 {
  color: #4b4b52;
}
.applicant-card-width {
  width: 32.5% !important;
}

@media screen and (min-width: 769px) and (max-width: 1398px) {
  .applicant-card-width {
    min-width: 48% !important;
    max-width: 48% !important;
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  .applicant-card-width {
    width: 100% !important;
  }
}

.card-height {
  height: 300px !important;
}
@media screen and (min-width: 2560px) {
  .card-height {
    height: auto;
  }
}

@media screen and (min-width: 1440px) and (max-width: 2559px) {
  .card-height {
    height: 300px !important;
  }
}

@media screen and (min-width: 987px) and (max-width: 1400px) {
  .card-height {
    height: 350px !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 986px) {
  .card-height {
    height: 275px !important;
  }
}
@media screen and (max-width: 768px) {
  .card-height {
    height: auto !important;
  }
}
