.achievement-badges {
  width: 90px;
  border-radius: 10px;
}
.card-background-color {
  background-color: #f5f8fa;
}
.salary-text {
  font-size: 0.95rem;
  color: #48465b;
  font-weight: 600;
}
.salary-heading {
  font-size: 0.8rem;
  color: #595d6e;
  font-weight: 600;
}
.salary-card {
  height: fit-content !important;
  max-width: 800px;
}
.experience-card {
  background-color: #f7f8fc;
  padding: 0.5rem 1rem 1rem 1.5rem;
  border-radius: 4px;
  /* max-width: 800px; */
  /* margin: 0px 0px 0px 40px; */
}
.tag-card {
  border-radius: 2rem;
  color: #41404d;
  font-size: 0.95rem;
  border: 2px solid #171718;
  background: #fff;
  width: auto;
  padding: 0.75rem 0.75rem;
}
.font-12 {
  font-size: 12px;
}
.icon-positioning {
  position: absolute;
}
.salary-badge-width {
  width: 50%;
}
.job-cards {
  min-width: 25%;
  max-width: 30% !important;
}
/* @media screen and (min-width: 1402px) and (max-width: 2550px) {
  .job-cards {
    min-width: 25%;
    max-width: 30% !important;
  }
}*/
@media screen and (max-width: 1402px) {
  .job-cards {
    width: 100% !important;
    max-width: 100% !important;
  }
  .salary-card {
    max-width: 100% !important;
  }
}
.current-stages {
  width: 100%;
  font-size: 12px;
  color: #595d6e;
  font-weight: 500;
  padding: 8px 16px;
  text-wrap: nowrap;
}
.divider {
  background-color: #848a8f3b;
  font-size: 1rem;
  width: 100% !important;
  min-height: 1px;
}
.w-100 {
  width: 100% !important;
}
.stage-dropdown {
  width: 260px;
  min-width: 260px !important;
  height: auto;
  padding-bottom: 10px;

  background-color: #ffffff;
  top: 43px;
  right: 10px;
  position: absolute;
  z-index: 99;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
}
.stages-row {
  padding: 8px 16px;
  font-size: 13px;
}
.stages-row:hover {
  background-color: rgba(241, 241, 241, 0.767);
  cursor: pointer;
}
.stages-row-active {
  padding: 8px 16px;
  font-size: 13px;
  background-color: #e2e8f0;
  margin: 0px 5px;
  border-radius: 5px;
}
.stages-row-active:hover {
  background-color: #e2e8f0;
  cursor: pointer;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border-radius: 8px;
  border: 1px solid gray;
}
.box-shadow {
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
}
.search-result {
  padding: 10px 15px;
  width: 100%;
}
.search-result:hover {
  background-color: rgba(241, 241, 241, 0.767);
  cursor: pointer;
}
.job-img {
  height: 30px;
  width: 30px;
  border-radius: 100%;
}
