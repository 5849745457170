.achievement-badges {
  width: 90px;
  border-radius: 10px;
}
.card-background-color {
  background-color: #f5f8fa;
}
.salary-text {
  font-size: 0.95rem;
  color: #48465b;
  font-weight: 600;
}
.salary-heading {
  font-size: 0.8rem;
  color: #595d6e;
  font-weight: 600;
}
salary-card {
  height: fit-content !important;
  max-width: 800px;
}
.experience-card {
  background-color: #f7f8fc;
  padding: 0.5rem 1rem 1rem 1.5rem;
  border-radius: 4px;
  /* max-width: 800px; */
  /* margin: 0px 0px 0px 40px; */
}
.tag-card {
  border-radius: 2rem;
  color: #41404d;
  font-size: 0.95rem;
  border: 2px solid #171718;
  background: #fff;
  width: auto;
  padding: 0.75rem 0.75rem;
}
.font-12 {
  font-size: 12px;
}
.icon-positioning {
  position: absolute;
}
.salary-badge-width {
  width: 50%;
}
.job-cards {
  width: auto;
}
@media screen and (min-width: 1402px) and (max-width: 2550px) {
  .job-cards {
    max-width: 35%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .job-cards {
    width: full !important;
  }
}
