//
// Light Sidebar(used by Light Sidebar layout only)
//

[data-kt-app-layout='light-sidebar'] {
  .app-sidebar {
    background-color: #1e1e2d;
    border-right: 0 !important;

    .hover-scroll-overlay-y {
      @include scrollbar-color(
        var(--#{$prefix}app-sidebar-light-scrollbar-color),
        var(--#{$prefix}app-sidebar-light-scrollbar-color-hover)
      );
    }

    .app-sidebar-logo {
      // border-bottom: 1px dashed $app-sidebar-dark-separator-color;
      border-bottom: none !important;
    }

    .menu {
      .menu-item {
        .menu-heading {
          color: $app-sidebar-dark-menu-heading-color !important;
        }

        @include menu-link-default-state(
          $title-color: #a2a3b7,
          $icon-color: #c5c5d8,
          $bullet-color: #787887,
          $arrow-color: #787887,
          $bg-color: null
        );

        @include menu-link-hover-state(
          $title-color: var(--#{$prefix}primary-inverse),
          $icon-color: var(--#{$prefix}primary-inverse),
          $bullet-color: var(--#{$prefix}primary-inverse),
          $arrow-color: var(--#{$prefix}primary-inverse),
          $bg-color: null
        );

        @include menu-link-here-state(
          $title-color: var(--#{$prefix}primary-inverse),
          $icon-color: var(--#{$prefix}primary-inverse),
          $bullet-color: var(--#{$prefix}primary-inverse),
          $arrow-color: var(--#{$prefix}primary-inverse),
          $bg-color: null
        );

        @include menu-link-show-state(
          $title-color: var(--#{$prefix}primary-inverse),
          $icon-color: var(--#{$prefix}primary-inverse),
          $bullet-color: var(--#{$prefix}primary-inverse),
          $arrow-color: var(--#{$prefix}primary-inverse),
          $bg-color: null
        );

        @include menu-link-active-state(
          $title-color: var(--#{$prefix}primary-inverse),
          $icon-color: var(--#{$prefix}primary-inverse),
          $bullet-color: var(--#{$prefix}primary-inverse),
          $arrow-color: var(--#{$prefix}primary-inverse),
          $bg-color: $app-sidebar-dark-menu-link-bg-color-active
        );
      }
    }
  }
}

[data-kt-app-layout='light-sidebar'][data-kt-app-header-fixed='true'] {
  .app-header {
    background-color: var(--#{$prefix}app-bg-color);
    box-shadow: none;
    border-bottom: 1px dashed var(--#{$prefix}app-sidebar-light-separator-color);
  }

  .app-sidebar {
    .app-sidebar-logo {
      // border-bottom: 1px dashed $app-sidebar-dark-separator-color;
      border-bottom: none !important;
    }
  }
}
