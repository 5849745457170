.achievement-badges {
  width: 90px;
  border-radius: 10px;
}
.card-background-color {
  background-color: #f5f8fa;
}
.salary-text {
  font-size: 0.95rem;
  color: #48465b;
  font-weight: 600;
}
.salary-heading {
  font-size: 0.8rem;
  color: #595d6e;
  font-weight: 600;
}
salary-card {
  max-width: 800px;
}
.experience-card {
  background-color: #f7f8fc;
  padding: 0.5rem 1rem 1rem 1.5rem;
  border-radius: 4px;
  /* max-width: 800px; */
  margin: 0px 0px 0px 40px;
}
.tag-card {
  border-radius: 2rem;
  color: #41404d;
  font-size: 0.95rem;
  border: 2px solid #171718;
  background: #fff;
  width: auto;
  padding: 0.75rem 0.75rem;
}
.white-Space-NoWrap {
  white-space: no;
  color: blue !important;
  /* #181c32 */
}
.font-12 {
  font-size: 12px;
}
.icon-positioning {
  position: absolute;
}
.salary-badge-width {
  width: 50%;
}
.cursor {
  cursor: pointer;
}
.gray-text {
  color: #41404d;
  width: 150px;
}
@media screen and (max-width: 430px) {
  .gray-text {
    color: #41404d;
    width: 110px !important;
  }
}
/* .job-card-width {
  width: 32.5% !important;
}
@media screen (min-width:768px) and (max-width: 768px) {
  .job-card-width {
    width: 100% !important;
  }
}
@media screen and (max-width: 768px) {
  .job-card-width {
    width: 100% !important;
  }
} */
.job-card-width {
  width: 32.5% !important;
}

@media screen and (min-width: 1023px) and (max-width: 1398px) {
  .job-card-width {
    min-width: 48% !important;
    max-width: 48% !important;
    overflow: hidden;
  }
}

@media screen and (max-width: 1022px) {
  .job-card-width {
    width: 100% !important;
  }
}
.job-card-height {
  height: 210px !important;
}
.new-jobs-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .new-jobs-grid {
    display: flex;
    flex-direction: column !important;
  }
}
.minH-75 {
  min-height: 75vh;
}
