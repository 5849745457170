.pointer {
  cursor: pointer;
}
.username-head {
  font-size: 1.1rem;
  color: #48465b;
  font-weight: 500;
}
.username-desctiption {
  color: #74788d;
  font-weight: 500;
}
.chat-receiver-card {
  display: inline-block;
  width: fit-content;
  /* min-width: 10px; */
  max-width: 500px;
  padding: 1.1rem 3.5rem 1.1rem 1.6rem;
  margin-top: 0.8rem;
  color: #6c7293;
  font-weight: 500;
  font-size: 1.1rem;
  border-radius: 4px;
  background-color: rgba(43, 194, 148, 0.1);
}
.chat-sender-card {
  display: inline-block;
  max-width: 500px;
  padding: 1.1rem 3.5rem 1.1rem 1.6rem;
  margin-top: 0.8rem;
  color: #6c7293;
  font-weight: 500;
  font-size: 1.1rem;
  border-radius: 4px;
  background-color: rgba(93, 120, 255, 0.1);
}
.message-card {
  /* overflow-y: scroll; */
  max-height: 65vh;
}
.main-card-height {
  height: 79vh;
}
.cursor {
  cursor: pointer;
}
.chat-search-card {
  padding: 5px 10px;
  background: rgb(247, 244, 244);
  position: absolute;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 5px;
  z-index: 50;
  top: 50px;
  /* right: 35px; */
  width: 100%;
  max-height: 200px;
  overflow: auto;
}
