.card-heading {
  font-size: 1.4rem;
  color: #48465b;
  font-weight: 500;
}
@media screen and (max-width: 425px) {
  .card-heading {
    font-size: 16px;
    color: #48465b;
    font-weight: 500;
  }
}
.font-weight-500 {
  font-weight: 500;
}
.badges-text {
  color: #48465b;
  font-size: 0.9rem;
}
.card-heading2 {
  color: #4b4b52;
}
.candidate-card-width {
  width: 32.5% !important;
}

@media screen and (min-width: 769px) and (max-width: 1398px) {
  .candidate-card-width {
    min-width: 48% !important;
    max-width: 48% !important;
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  .candidate-card-width {
    width: 100% !important;
    max-width: 100 !important;
  }
}

.candidate-card-height {
  height: 300px !important;
}
@media screen and (min-width: 1701px) {
  .candidate-card-height {
    height: auto;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1700px) {
  .candidate-card-height {
    height: 330px !important;
  }
}

@media screen and (min-width: 1091x) and (max-width: 1399px) {
  .candidate-card-height {
    height: 400px !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1090px) {
  .candidate-card-height {
    height: 410px !important;
  }
}
@media screen and (min-width: 827px) and (max-width: 991px) {
  .candidate-card-height {
    height: 300px !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 826px) {
  .candidate-card-height {
    height: 310px !important;
  }
}
@media screen and (max-width: 768px) {
  .candidate-card-height {
    height: auto !important;
  }
}

.bi-three-dots {
  position: absolute;
  top: 5px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
}

.dot-menu {
  display: none;
  position: absolute;
  top: 40px;
  right: -1px !important;
  list-style: none;
  padding: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
}

.dot-menu li {
  padding: 5px 10px;
  cursor: pointer;
}

.show-menu {
  display: block !important;
}
.menu-font {
  font-weight: 700;
}
.ribbon-left {
  right: 420px;
  margin-bottom: 15px !important;
}
.active-candidate-icon {
  padding: 12px 9px;
  border-radius: 0px 0px 5px 5px;
  position: absolute;
  left: 25px;
}
.company-card-body {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 425px) {
  .company-card-body {
    display: flex;
    flex-direction: column;
  }
}
