.full-width {
  width: 100% !important;
}
/* CustomPagination.css */
.pagination {
  display: flex;
  list-style: none;
  gap: 5px;
  padding: 0;
  margin: 20px 0;
  justify-content: center;
}

.pagination a {
  text-decoration: none;
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
}

.pagination a:hover {
  background-color: #0056b3;
}

.pagination .active a {
  background-color: #0056b3;
}

.pagination .disabled a {
  background-color: #ddd;
  color: #777;
  cursor: not-allowed;
}

.pagination .break a {
  pointer-events: none;
  cursor: default;
}

.employee-width {
  width: 32.5% !important;
}

@media screen and (min-width: 1024px) and (max-width: 1398px) {
  .employee-width {
    min-width: 48% !important;
  }
}

@media screen and (max-width: 1023px) {
  .employee-width {
    width: 100% !important;
  }
}
.employee-height {
  height: 220px;
}
.button-gap {
  margin-right: 40px;
}
.button-container {
  display: flex;
  justify-content: space-between;
}
.equal-width-button {
  flex: 1;
}
.bi-three-dots {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}
.dot-menu {
  display: none;
  position: absolute;
  top: 25px;
  right: -30px;
  list-style: none;
  padding: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  width: 110px;
  padding: 5px;
}
.dot-menu li {
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
}
.show-menu {
  display: block !important;
}
.menu-font {
  font-weight: 700;
}
.round-button {
  flex: 1;
  border-radius: 50%;
}
.card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 100%;
}

.checkbox {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
  width: 18px;
  height: 18px;
}
.centered-button {
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  font-size: 16px;
}
.showonMobile {
  display: flex !important;
}
@media screen and (min-width: 700px) {
  .showonMobile {
    display: none !important;
  }
}
.showonLgScreen {
  display: flex !important;
  flex-direction: row;
}
@media screen and (max-width: 700px) {
  .showonLgScreen {
    display: none !important;
  }
}
.minH-75 {
  min-height: 75vh;
}
.company-image {
  height: 100px;
  width: 100px;
}
@media screen and (max-width: 425px) {
  .company-image {
    height: 75px;
    width: 75px;
  }
}
