.options-border {
  border: 1px solid #009ef7 !important;
}
.slider-width {
  width: 40%;
}
.select-all-btn {
  font-size: 14px;
  width: auto;
  height: auto;
  align-items: center;
  justify-content: center;
  transform: scale(0.7);
}
