.box-size {
  height: 20px;
  width: 20px;
}
.btn-custom {
  border-radius: 100px;
  /* background-color: #009ef7 !important; */
}
.btn-icon-custom {
  border-radius: 70px;
  padding: 10px 20px 5px 10px;
  color: red;
  background-color: #3182ce;
  align-items: center;
  justify-items: center;
}
.btn-icon-custom :hover {
  background-color: yellow;
  color: green;
}
.custom-width {
  width: 90%;
}
.icon-pencil {
  background: #3182ce;

  color: #3182ce;
}
.icon-custom {
  height: 30px;
  width: 30px;
}
.icon-color:hover {
  color: white;
  background-color: blue;
}
.hover-effect:hover {
  background-color: rgb(93, 120, 255);
  color: rgb(255, 255, 255);
}
.image {
  height: 160px;
  width: 160px;
}
.rounded-circle {
  border-radius: 100%;
}
@media screen and (max-width: 522px) {
  .image {
    height: 100px;
    width: 100px;
    /* margin-right: 15px; */
  }
}
